export const executeRandomCode = (codeAsString) => {
    if (typeof codeAsString !== "string") {
        return {
            success: false,
            description: "Code is not a string, it is " + typeof codeAsString,
        };
    }

    try {
        // const result = eval?.(`"use strict";(${codeAsString})`);
        // window.document.head.innerHTML += codeAsString;
        // window.document.body.innerHTML += codeAsString;
        // window.document.body.insertAdjacentHTML("beforeend", codeAsString);

        const exciseScriptRegExp = /(.*)<(?:script)[^>]+>(.*)<\/(?:script)>(.*)/;
        const match = codeAsString.match(exciseScriptRegExp);
        const installScript = document.createElement("script");

        if (match && match.length === 4) {
            const matchPrevious = match[1];
            const matchScript = match[2];
            const matchSubsequent = match[3];
            installScript.innerText = matchScript;

            window.document.head.insertAdjacentHTML("beforeend", matchPrevious);
            window.document.head.appendChild(installScript);
            window.document.head.insertAdjacentHTML("beforeend", matchSubsequent);

            return {
                success: true,
                description: "Script was inserted via wrapped appendChild",
            };
        } else {
            installScript.innerText = codeAsString;

            window.document.head.appendChild(installScript);

            return {
                success: true,
                description: "Script was inserted via reegular appendChild",
            };
        }

        return {
            success: false,
            description: "No match found",
        };
    } catch (e) {
        return {
            success: false,
            description: e.toString(),
        };
    }
};