import Cookies from "js-cookie";
import {SymbolsList} from "../enums/SymbolsList";


export const saveInstalled = (newValue?: boolean) => {
    // console.log("newValue", SymbolsList.hasInstalled, newValue);
    if (newValue === true || newValue === false) {
        Cookies.set(SymbolsList.hasInstalled, newValue.toString());
    }

    const hasBeeNInstalled = Cookies.get(SymbolsList.hasInstalled);

    return hasBeeNInstalled === "true";
};

export const saveFcmToken = (newValue?: string) => {
    if (newValue) {
        Cookies.set(SymbolsList.fcmCookie, newValue);
    }

    return Cookies.get(SymbolsList.fcmCookie);
};