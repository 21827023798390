// https://developers.facebook.com/docs/meta-pixel/reference#standard-events

export enum FacebookPixelStandardEvents {
    AddPaymentInfo = "AddPaymentInfo",
    AddToCart = "AddToCart",
    AddToWishlist = "AddToWishlist",
    CompleteRegistration = "CompleteRegistration",
    Contact = "Contact",
    CustomizeProduct = "CustomizeProduct",
    Donate = "Donate",
    FindLocation = "FindLocation",
    InitiateCheckout = "InitiateCheckout",
    Lead = "Lead",
    Purchase = "Purchase",
    Schedule = "Schedule",
    Search = "Search",
    StartTrial = "StartTrial",
    SubmitApplication = "SubmitApplication",
    Subscribe = "Subscribe",
    ViewContent = "ViewContent",
}