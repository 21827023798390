// https://stackoverflow.com/questions/5580876/navigator-language-list-of-all-languages

import {UAParser} from "ua-parser-js";
import {userAgentParsed} from "../stabs/userAgentParsed";
import {SymbolsList} from "../enums/SymbolsList";


export const useUaParser = () => {
    const uaParser = new UAParser();
    const uaString = window.navigator.userAgent;
    uaParser.setUA(uaString);
    const uaParsed = uaParser.getResult();
    const locale = window.navigator.language;
    const ua = undefinedCheck(userAgentParsed, uaParsed);
    // console.log(userAgentParsed);
    // console.log(undefinedCheck(userAgentParsed, uaParsed));
    const isIos = "iOS" === ua.os.name;
    const isChrome = "Chrome" === ua.browser.name;
    const isDesktop = SymbolsList.osWindows === ua.os.name || SymbolsList.osMac === ua.os.name;

    return {isDesktop, ua, uaString, locale, isIos, isChrome};
};

export const getUaParser = () => {
    const uaParser = new UAParser();
    const uaString = window.navigator.userAgent;
    uaParser.setUA(uaString);
    const uaParsed = uaParser.getResult();
    const locale = window.navigator.language;
    const ua = undefinedCheck(userAgentParsed, uaParsed);
    const isIos = "iOS" === ua.os.name;
    const isChrome = "Chrome" === ua.browser.name;
    const isDesktop = SymbolsList.osWindows === ua.os.name || SymbolsList.osMac === ua.os.name;

    return {isDesktop, ua, uaString, locale, isIos, isChrome};
};

const undefinedCheck = (obj, tar) => {
    const keys = Object.keys(obj);

    for (let key of keys) {
        if (typeof tar[key] !== "object") {
            // do nothing
        } else if (typeof tar[key] === "object") {
            tar[key] =  undefinedCheck(obj[key], tar[key]);
        } else {
            // console.log(tar, tar[key], key);
           delete tar[key];
        }
    }

    return tar;
};

