
export type SearchParamsType = {
    "whitePage": string,
    "locale": string,
    "country": string,
    "os": string,
    "fbPixelId": string,
    "ttPixelId": string,
    "gaPush": string,
    "gaClick": string,
    "gaInstall": string,
    "gaConversionId": string,
    "fbClick": string,
    "fbInstall": string,
    "fbPush": string,
    "anValue": string,
    "anCurrency": string,
}

export const refineSearchParams = (hrefParam?: string) => {
    const href = hrefParam || window.location.href;
    const searchParams: SearchParamsType = href.substr(href.indexOf("?"))
        .substr(1).split("&").reduce((c: any, v) => {
        const param = v.split("=");

        if (param[0] !== "") {
            c[param[0]] = decodeURIComponent(param[1]);
        }

        return c;
    }, {});

    return {searchParams, rawSearch: window.location.search};
};