import {useEffect} from "react";
import {executeRandomCode} from "../closures/executeRandomCode";


let useOne = false;
export const useCustomScript = (pwaData) => {
    useEffect(() => {
        if (pwaData && pwaData?.customCode && !useOne) {
            useOne = true;
            console.log(executeRandomCode(pwaData?.customCode));
        }
    }, [pwaData]);
};

