import {useEffect, useState} from "react";
import {useUaParser} from "./useUaParser";
import {getHostDocumentId} from "../closures/getHostDocumentId";

export const useApplicationActivated = () => {
    const [ready, setReady] = useState(false);
    const {ua} = useUaParser();
    const {isPreview} = getHostDocumentId();

    useEffect(() => {
        if (ua?.browser?.name !== "Safari" && "serviceWorker" in window.navigator && !isPreview) {
            window.navigator.serviceWorker.ready.then(registration => {
                setReady(true);
                console.log("application live ready");
            });
            // console.log("Standard oil ", ua?.browser?.name);
        } else {
            setReady(true);
            console.log("application dry-run ready");
            // console.log("Non Standard oil ", ua?.browser?.name);
        }

        // if ("BroadcastChannel" in window) {
        //     const channel = new BroadcastChannel('sw-messages');
        //     channel.addEventListener('message', event => {
        //         if (event.data && event.data.type === "activate") {
        //             setReady(true);
        //             console.log('channel.addEventListener - Received', event.data, "BroadcastChannel" in window);
        //         }
        //     });
        // } else {
        //     setReady(true);
        // }

        // window.document.head.onload = () => {
        //     console.log("head is loaded");
        // };
        // window.document.body.onload = () => {
        //     console.log("body is loaded");
        // };
        // window.onload = () => {
        //     setReady(true);
        //     console.log("setReady(true);");
        // };
        // window.document.addEventListener("readystatechange", e => {
        //     console.log("window.document.readyState", window.document.readyState);
        //     if (window.document.readyState === "complete") {
        //         console.log("setReady(true);");
        //         setReady(true);
        //     }
        // });
    }, [ready, ua, isPreview]);

    return ready;
};