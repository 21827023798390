import {refineSearchParams} from "./refineSearchParams";


let hasNotInit = true;
export const initSnapPixelIfNot = (pixel: string) => {
    if (hasNotInit) {
        !(function(e,t,n,r){if(e.snaptr)return;var a=e.snaptr=function() // eslint-disable-line
                {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)}; // eslint-disable-line
                a.queue=[];var s='script';r=t.createElement(s);r.async=!0; // eslint-disable-line
                r.src=n;var u=t.getElementsByTagName(s)[0]; // eslint-disable-line
                u.parentNode.insertBefore(r,u);})(window,document, // eslint-disable-line
                'https://sc-static.net/scevent.min.js'); // eslint-disable-line
        
        hasNotInit = false;
    }
    console.log("Trying to init a snapchat pixel", pixel);
    window.snaptr('init', pixel);
};

const trackPageView = () => {
    window.snaptr('track', 'PAGE_VIEW');
};

export const initSnapPixels = (pwaData) => {
    const { searchParams: { scp } } = refineSearchParams();
    const pixels = pwaData?.snapchatPixels?.map(p => p.id) || [];

    if (pwaData?.snapPixelAutoAppend) {
        if (scp && pixels.indexOf(scp) === -1) {
            pixels.push(scp);
        }

        pixels.forEach(p => initSnapPixelIfNot(p));
    } else if (scp) {
        initSnapPixelIfNot(scp);
    } else {
        return;
    }

    trackPageView();
};