
let installPrompt: any = null;

const beforeInstallPrompt = (event: Event) => {
    event.preventDefault();
    installPrompt = event;
    // console.log("beforeInstallPrompt", event);
    window.removeEventListener("beforeinstallprompt", beforeInstallPrompt);
};

window.removeEventListener("beforeinstallprompt", beforeInstallPrompt);
window.addEventListener("beforeinstallprompt", beforeInstallPrompt);

export const listenToBeforeInstallPrompt = (onBeforeAccepted, onSuccessAccepted = (e) => {}, onFailAccepted = (e) => {}) : Function => {
    return async (e: Event) => {
        await onBeforeAccepted(e);

        // console.log(installPrompt, e.target);

        if (!installPrompt) {
            return;
        }

        try {
            const result = await installPrompt.prompt();
            // console.log(`Install prompt was: ${result.outcome}`);
            // console.log(`Install prompt object was:`, result);

            if (result.outcome === "accepted") {
                await onSuccessAccepted(e);
                return true;
            } else {
                onFailAccepted(e);
            }
        } catch (e) {
            // console.log(e);
            onFailAccepted(e);
        }
    };
};