// import {doFacebookPixel,initPixelIfNot} from "./doFacebookPixel";
import {initPixelIfNot} from "./doFacebookPixel";
import {doGaConversion} from "./doGaConversion";
import {refineSearchParams} from "./refineSearchParams";
import {AnalyticsEvent} from "../enums/AnalyticsEvents";
import {AnalyticsActions} from "../enums/AnalyticsActions";
import type { TrackerAction } from "../types/TrackerAction";
import {initSnapPixels} from "./snapchatPixel";
// import {ShowingLogs} from "../enums/ShowingLogs";


export const initCounters = (pwaData) => {
    const { searchParams } = refineSearchParams();
    const { fbp } = searchParams;
    const pixels = pwaData.fbPixels.map(p => p.id).filter(pixel => fbp !== pixel);

    if (pwaData?.fbPixelAutoAppend && pixels.length > 0) {
        if (fbp) {
            pixels.push(fbp);
        }

        initPixelIfNot(pixels);
    } else if (fbp) {
        initPixelIfNot(fbp);
    }
};

export const universalInit = (pwaData) => {
    initCounters(pwaData);
    initSnapPixels(pwaData);
};

export const doAnalyticsNotification = async (e: AnalyticsEvent, pwaData) => {
    const { searchParams } = refineSearchParams();
    const { fbp } = searchParams;
    /*const facebookEvent = pwaData?.trackers?.fb[AnalyticsActions[e].fb] || { action: AnalyticsActions[e].fbd };*/
    const googleAction: TrackerAction = pwaData?.trackers?.ga[AnalyticsActions[e].ga];
    const facebookPixel = fbp || searchParams[AnalyticsActions[e].fb] || pwaData?.trackers?.fb?.fbPixelId;
    const googleId = searchParams[AnalyticsActions[e].ga] || pwaData?.trackers?.ga?.gaConversionId;

    // if (ShowingLogs.useAnalyticsNotification) {
    //     console.log(
    //         "useAnalyticsNotification",
    //         e,
    //         AnalyticsActions[e],
    //         facebookPixel,
    //         facebookEvent,
    //         AnalyticsActions[e].fbd,
    //         googleId,
    //         googleAction,
    //         pwaData,
    //         searchParams[AnalyticsActions[e].fb],
    //         searchParams[AnalyticsActions[e].ga]
    //     );
    // }

    initPixelIfNot(facebookPixel);
    /*doFacebookPixel(facebookEvent, facebookPixel);*/
    doGaConversion(googleAction, googleId);

};