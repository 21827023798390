let startedOnce = false;
export const onceBackBait = (offerLink) => {
    if (!startedOnce) {
        window.addEventListener("popstate", (event) => {
            // If a state has been provided, we have a "simulated" page
            // and we update the current page.
            if (typeof event.state === "string") {
                // Simulate the loading of the previous page
                // console.log(event.state);
                window.location.href = event.state;
            }
        });

        for (let i = 0; i < 3; i++) {
            window.history.pushState(offerLink, "");
        }
        // console.log("window.history.pushState(offerLink, \"\");", offerLink);
        // console.log("window.history", window.history.state);
        startedOnce = true;
    }
};