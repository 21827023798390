import {useContext, useEffect, useState} from "react";
import {collection, doc, getFirestore, setDoc, updateDoc} from "@firebase/firestore";
// import {useIpInfo} from "./useIpInfo";
import {useUuidWrite} from "./useUuidWrite";
import {useUaParser} from "./useUaParser";
// import {useStorePageDataModel} from "./useStorePageDataMode";
import {initializeApp} from "@firebase/app";
import {Timestamp} from "firebase/firestore";
import {refineCookies} from "../closures/refineCookies";
import {FcmTokenContext} from "../closures/getUserFCMToken";
import {checkUndefinedInObject} from "../closures/checkUndefinedInObject";
import {refineSearchParams} from "./refineSearchParams";


const firebaseConfig = {
    apiKey: "AIzaSyAEJiRCsBPQFvtCov_fqkAoZRmaML5DPdg",
    authDomain: "pwa-bot-99957.firebaseapp.com",
    projectId: "pwa-bot-99957",
    storageBucket: "pwa-bot-99957.appspot.com",
    messagingSenderId: "394288007076",
    appId: "1:394288007076:web:63108fbc898d6f98c4a331",
    measurementId: "G-SRSWLYPZZB"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const people = collection(db, "people");

export const useSavePeople = (pwaData, ipInfo, recordId) : {uuid: string, setUuid: Function, uuidWasGenerated: boolean} => {
    // const { ipInfo } = useIpInfo();
    const { uuid, setUuid, uuidWasGenerated } = useUuidWrite();
    const { ua, locale } = useUaParser();
    // const { pwaData, recordId } = useStorePageDataModel();
    const { fcmToken } = useContext(FcmTokenContext);
    // console.log("fcmToken", "useSavePeople", fcmToken);
    const {searchParams} = refineSearchParams();
    const [theFirst, setTheFirst] = useState(false);

    if (!theFirst && uuidWasGenerated) {
        setTheFirst(true);
    }

    useEffect(() => {
        // console.log(ipInfo, uuid , pwaData , theFirst);
        if (ipInfo && uuid && pwaData && theFirst) {
            // const { browser, device, engine, os } = ua;
            // console.log({ ua, locale,  ipInfo, });
            // console.log("searchParams", searchParams);
            (async () => {
                // console.log("searchParams", searchParams);
                await setDoc(doc(people, uuid), checkUndefinedInObject({
                    cookies: refineCookies(),
                    pwaId: recordId,
                    fcmToken,
                    searchParams,
                    href: window.location.href,
                    // browser,
                    // device,
                    // engine,
                    // os,
                    locale,
                    ua: ua.ua,
                    ipInfo,
                    // visitTime: (new Date()).toISOString(),
                    visitTime: Timestamp.now(),
                    acid: pwaData.uid,
                }));
            })();
        }
    }, [ipInfo, uuid, pwaData, locale, recordId, ua, fcmToken, searchParams, theFirst]);

    return {uuid, setUuid, uuidWasGenerated, fcmToken};
};

export const updateClockState = async (isCloak: boolean, uuid: string) => {
    try {
        if (uuid) {
            // console.log("updateClockState", isCloak, uuid);
            return await updateDoc(doc(people, uuid), {
                isCloak
            });
        }
    } catch (e) {
        console.log(e);
    }
};

export const updateFcmToken = async (fcmToken: string, uuid: string) => {
    try {
        if (uuid && fcmToken) {
            // console.log("updateClockState", isCloak, uuid);
            return await updateDoc(doc(people, uuid), {
                fcmToken
            });
        }
    } catch (e) {
        console.log(e);
    }
};
