import Cookies from "js-cookie";
import {SymbolsList} from "../enums/SymbolsList";


export const recoverInitialLocation = (installed: boolean)  => {
    const initialHref = Cookies.get(SymbolsList.initialHref);

    if (!initialHref) {
        Cookies.set(SymbolsList.initialHref, window.location.href, { expires: 7 });
    } else if (window.location.href !== initialHref && installed) {
        // window.location.href = initialHref;
        window.history.replaceState({},"title", initialHref);
    }

    return {initialHref};
};