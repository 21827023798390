// import { getMessaging } from "firebase/messaging";
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import { createContext, useState } from 'react';
// import {useEffect, useState} from "react";


const firebaseConfig = {
    apiKey: "AIzaSyAEJiRCsBPQFvtCov_fqkAoZRmaML5DPdg",
    authDomain: "pwa-bot-99957.firebaseapp.com",
    projectId: "pwa-bot-99957",
    storageBucket: "pwa-bot-99957.appspot.com",
    messagingSenderId: "394288007076",
    appId: "1:394288007076:web:63108fbc898d6f98c4a331",
    measurementId: "G-SRSWLYPZZB"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = firebase.messaging();

export const getUserFCMToken = async (GetTokenOptions) => {
    return messaging.getToken(GetTokenOptions);
    // return messaging.getToken({vapidKey: "BAqQ8OVIC8P-2qs7IIMfazu0smo7veSGYksYZtqPSvm9WEszlGQTXAPHJaS"});
};

export const getUserFCMTokenSimple = async () => {
    // const [registration, setRegistration] = useState(null);
    //
    // if (trigger) {
    //     useEffect(() => {
    //         (async () => {
    //             const registration = await navigator.serviceWorker.ready;
    //             setRegistration(await messaging.getToken({serviceWorkerRegistration: registration}));
    //         })();
    //     }, []);
    // });
    // return registration;

    const registration: ServiceWorkerRegistration = await navigator.serviceWorker.ready;
    // return messaging.getToken({serviceWorkerRegistration: registration});
    return messaging.getToken({
        serviceWorkerRegistration: registration,
        vapidKey: "BAqQ8OVIC8P-2qs7IIMfazu0smo7veSGYksYZtqPSvm9WEszlGQTXAPHJaS6F42zbw5fgtO2Z4xi4uCJyEhkEx8",
    });
};

export const getUserFCMTokenComplex = async (registration: ServiceWorkerRegistration) => {
    try {
        return messaging.getToken({
            serviceWorkerRegistration: registration,
            vapidKey: "BAqQ8OVIC8P-2qs7IIMfazu0smo7veSGYksYZtqPSvm9WEszlGQTXAPHJaS6F42zbw5fgtO2Z4xi4uCJyEhkEx8",
        });
    } catch (e) {
        console.log("getUserFCMTokenComplex", e);
    }
};


export const FcmTokenContext = createContext(null);

export const FcmTokenProvider = ({children}) => {
    const [fcmToken, setToken] = useState(null);

    
    return (
        <FcmTokenContext.Provider value={{fcmToken, setToken}}>{children}</FcmTokenContext.Provider>
    );
};


