import {getHostDocumentId} from "./getHostDocumentId";
import {useEffect, useState} from "react";

// export let isScriptLoaded = null;
const optionsLink = "/source/js/store-page-data-model.js";
let oneUseLeft = true;

export const useInitSettings = () => {
    const [ip, setIp]: [null | string, Function] = useState(null);
    const [city, setCity]: [null | string, Function] = useState(null);
    const [country, setCountry]: [null | string, Function] = useState(null);
    const [region, setRegion]: [null | string, Function] = useState(null);
    const [reload, setReload] = useState(false);
    const {isPreview, documentId} = getHostDocumentId();
    // if (isPreview && hostname !== "localhost") {
    // if (hostname !== "localhost") {
        // const scriptElement = window?.document?.createElement("script");
        // scriptElement.src = `/${documentId}${optionsLink}`;
        // const afterInsert = window?.document?.head?.insertBefore(scriptElement, window?.document?.head?.children[0]);


        // return isScriptLoaded = new Promise(async resolve => {
        //     if (isPreview) {
    useEffect(() => {
        (async () => {
            if (oneUseLeft) {
                oneUseLeft = false;
                const previewAddress = isPreview ? `https://preview.pwa.bot/${documentId}${optionsLink}` : optionsLink;
                // console.log("previewAddress", previewAddress);
                const fetchOptionsResult = await fetch(previewAddress);
                const codeAsString = await fetchOptionsResult.text();
                // console.log("fetchOptionsResult", fetchOptionsResult);
                if (fetchOptionsResult.status === 200) {
                    eval(codeAsString); // eslint-disable-line
                    setIp(fetchOptionsResult.headers.get("X-Ip"));
                    setCountry(fetchOptionsResult.headers.get("X-Country"));
                    setCity(fetchOptionsResult.headers.get("X-City"));
                    setRegion(fetchOptionsResult.headers.get("X-Region"));
                }

                if (!fetchOptionsResult.headers.get("X-Ip")) {
                    const fetchGeoResult: Response = await fetch("https://get.geojs.io/v1/ip/geo.json");
                    const toJsonResult = await fetchGeoResult.json();
                    setIp(toJsonResult.ip);
                    setCountry(toJsonResult.country_code);
                    setCity(toJsonResult.city);
                    setRegion(toJsonResult.region);
                }
                // console.log("fetchOptionsResult", fetchOptionsResult);
                // for (const one of fetchOptionsResult.headers.entries()) {
                //     console.log(one);
                // }
                setReload(true);
                // const scriptElement = window?.document?.createElement("script");
                // const childScript = window?.document?.head?.appendChild(scriptElement);
                // childScript.innerText = await fetchOptionsResult.text();
                // console.log("childScript.innerText", childScript.innerText);
            }
        })();
    });
                // resolve(true);
            // } else {
            //     const fetchOptionsResult = await fetch(optionsLink);
            //     const ipHeader = fetchOptionsResult.headers.get("X-Ip");
            //     const countryHeader = fetchOptionsResult.headers.get("X-Country");
            //     const cityHeader = fetchOptionsResult.headers.get("X-City");
            //     const scriptElement = window?.document?.createElement("script");
            //     const childScript = window?.document?.head?.appendChild(scriptElement);
            //     childScript.innerText = await fetchOptionsResult.text();
            //     console.log(childScript.innerText);
            //     // resolve(true);
            // }
            // afterInsert.addEventListener("load", e => {
            //     console.log("initIdSettings", e);
            //     resolve(true);
            // });
        // });
    // } else {
    //     return Promise.resolve(true);
    //
    // }
    return {ip, city, country, region, reload};
};
