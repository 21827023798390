export const checkUndefinedInObject = (obj) => {
    if (typeof obj === "object" && obj !== null) {
        const keys = Object.keys(obj);

        for (let key of keys) {
            if (typeof obj[key] === "undefined" || obj[key] === null) {
                delete obj[key];
            } if (typeof obj[key] !== "object") {
                // do nothing
            } else if (typeof obj[key] === "object") {
                obj[key] = checkUndefinedInObject(obj[key]);
            }
        }
    }

    return obj;
};