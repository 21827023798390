import{useEffect} from "react";
import {useColorScheme} from "@mui/material/styles";

export const whatMediaPreferred = () => {
    const light = window.matchMedia("(prefers-color-scheme: light)").matches;

    return light ? "light" : "dark";
};

export const useParticularTheme = newMode => {
    const { mode, setMode } = useColorScheme();
    // console.log("useParticularTheme", "mode", `${mode} => ${newMode}`);

    useEffect(() => {
        setMode(newMode);
    });

    return newMode;
};

