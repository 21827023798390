import Cookies from "js-cookie";
import {SymbolsList} from "../enums/SymbolsList";

export const refineCookies = () => {
    return {
        installed: Cookies.get(SymbolsList.hasInstalled) || "",
        uuid: Cookies.get(SymbolsList.uuid) || "",
        fbc: Cookies.get(SymbolsList.fbc) || "",
        fbp: Cookies.get(SymbolsList.fbp) || "",
        bbg: Cookies.get(SymbolsList.bbg) || "",
    };
};