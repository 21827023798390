// import {useEffect} from "react";
// import {UAParser} from "ua-parser-js";
import {getUaParser} from "./useUaParser";

interface useRedirectParams
{
    fbp: string,
    fbc: string,
}

export const doRedirect = ({fbp, fbc}: useRedirectParams) => {
    const {ua} = getUaParser();
    // console.log("ua.browser.name", ua.browser.name);

    // useEffect(() => {
        setTimeout(() => {
            // const uaParser = new UAParser();
            // uaParser.setUA(window.navigator.userAgent);

            try {
                if (
                    "Chrome" !== ua.browser.name &&
                    "Safari" !== ua.browser.name &&
                    "Mobile Safari" !== ua.browser.name
                ) {
                    const urlObject = new URL(window.location.href);
                    urlObject.searchParams.set("_fbp", fbp);
                    urlObject.searchParams.set("_fbc", fbc);

                    /*const a = window.document.body.appendChild(window.document.createElement("a"));
                    a.setAttribute("href", `intent://${urlObject.hostname}/?${urlObject.searchParams.toString()}#Intent;scheme=https;package=com.android.chrome;end;`);
                    a.click();*/

                    // window.location.href = `intent://${urlObject.hostname}/?${urlObject.searchParams.toString()}#Intent;scheme=https;package=com.android.chrome;end;`;
                    window.location.href = `intent://navigate?url=${window.location.hostname}/?${urlObject.searchParams.toString()}#Intent;scheme=googlechrome;end;`;
                }
            } catch (e) {
                console.log(e);
            }
        }, 1000);
    // }, [fbp, fbc]);
};
