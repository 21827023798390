// https://support.google.com/google-ads/answer/7548399?hl=en
// @ts-ignore
import ReactGA from "react-ga4";
import type { TrackerAction } from "../types/TrackerAction";


let hasNotInit = true;

const initIfNot = (googleId: string) => {
    if (hasNotInit) {
        ReactGA.initialize(googleId);

        hasNotInit = false;
    }
};

export const doGaConversion = (conversionTrack: TrackerAction, googleId: string) => {
    if (googleId || !hasNotInit) {
        initIfNot(googleId);

        ReactGA.gtag('event', 'conversion', {
            'send_to': `${googleId}/${conversionTrack.action}`,
            'value': conversionTrack.value,
            'currency': conversionTrack.currency,
        });
    }
};


