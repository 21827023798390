import Cookies from "js-cookie";

import {useState} from "react";
import {getUuid} from "../closures/getUuid";
import {SymbolsList} from "../enums/SymbolsList";


let uuidWasGenerated = false;
export const useUuidWrite = () : {uuid: string, setUuid: Function, uuidWasGenerated: boolean} => {
    const uuidFromCookie = Cookies.get(SymbolsList.uuid) || SymbolsList.empty;
    const [uuid, setUuidOriginal] = useState(uuidFromCookie);

    const setUuid = (newUuid: string) => {
        Cookies.set(SymbolsList.uuid, newUuid, { expires: 7 });
        setUuidOriginal(newUuid);
    };

    if (uuid === SymbolsList.empty) {
        setUuid(getUuid());
        uuidWasGenerated = true;
    }

    return {uuid, setUuid, uuidWasGenerated};
};