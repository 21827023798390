import { useEffect, useState } from 'react';
import {transformPageData, applyTranslation} from "pwa-bot-store-page-module";
import {useInitSettings} from "../closures/useInitSettings";


export const useStorePageDataModel = () => {
    const [StorePageDataModel, setStorePageDataModel]: [DocumentData|null, Function] = useState(null);
    const [recordId, setRecordId]: [string|null, Function] = useState(null);
    const [pwaData, setPwaData] = useState(null);
    const {ip, city, country, region, reload} = useInitSettings();

    const ipInfo = {
        ipAddress: ip,
        continentCode: "",
        continentName: "",
        countryCode: country,
        countryName: "",
        stateProv: region,
        city: city,
    };

    // const [domainData, setDomainData] = useState(null);
    // const declareStorePageDataModel = async (recordIdLocal: string) => {
    //     const decorationSnapshot = await getDoc(doc(pwa, recordIdLocal));
    //     // const decorationSnapshot = await getDoc(doc(decoration, recordIdLocal));
    //
    //     if (decorationSnapshot.exists()) {
    //         console.log(decorationSnapshot.data());
    //         setStorePageDataModel(transformPageData(decorationSnapshot.data() as AdminFormat));
    //     } else {
    //         console.warn("No such document");
    //     }
    // };
    //
    // const declarePwaData = async (recordIdLocal: string) => {
    //     const pwaSnapshot = await getDoc(doc(pwa, recordIdLocal));
    //     console.log(pwaSnapshot.data());
    //     setPwaData(pwaSnapshot.data());
    // };

    /*const declareBothFromOne = async (recordIdLocal: string) => {
        const pwaSnapshot = await getDoc(doc(pwa, recordIdLocal));
        // console.log(pwaSnapshot.data());
        setStorePageDataModel(transformPageData(pwaSnapshot.data() as AdminFormat));
        setPwaData(pwaSnapshot.data());
        setRecordId(pwaSnapshot.id);
    };*/

    // const getDecoration = async () => {
    //     let recordId: string|null = null;
    //     const q = query(domains, where("hosts", "array-contains", hostname));
    //     const dayStart = (new Date()).toISOString().substring(0, 10);
    //     const dayEnd = (new Date((new Date()).setDate((new Date()).getDate() + 1))).toISOString().substring(0, 10);
    //     const amountOfPeople = query(
    //         people,
    //         where("visitTime", ">", dayStart),
    //         where("visitTime", "<", dayEnd)
    //     );
    //     const domainSnapshot = await getDocs(q);
    //     const peopleDay = await getDocs(amountOfPeople);
    //
    //     peopleDay.forEach( doc => {
    //         console.log(doc.id, doc.data());
    //     }, "");
    //
    //     domainSnapshot.forEach( doc => {
    //         console.log(doc.id, doc.data());
    //         setRecordId(recordId = doc.id);
    //         setDomainData(doc.data());
    //     }, "");
    //
    //     console.log("recordId", recordId);
    //
    //     return recordId;
    // };

    // const writeDecoration = async data => {
    //     await setDoc(doc(decorationCollection, "yF4S6pj0dZgz2zmbzxeX"), data);
    // };




    useEffect(() => {
        (async () =>{
            // console.log("documentId, isPreview, hostname", documentId, isPreview, hostname);
            // console.log("window.pwaDataFromFile", window.pwaDataFromFile);
            
            if (window.pwaDataFromFile) {
                const pwaDataFromFileTranslated = applyTranslation(
                    transformPageData(window.pwaDataFromFile as AdminFormat),
                    window.pwaDataFromFile.defaultLanguage
                );
                // console.log("pwaDataFromFileTranslated", pwaDataFromFileTranslated);
                setStorePageDataModel(pwaDataFromFileTranslated);
                setPwaData(window.pwaDataFromFile);
                setRecordId(window.pwaDataFromFile.id);
                // console.log("pwaDataFromFileTranslated", pwaDataFromFileTranslated);
            } else {
                /*if (isPreview && documentId) {
                    await declareBothFromOne(documentId);
                }*/
                // else {
                //     const recordIdLocal: string|null = await getDecoration();
                //     // await writeDecoration(store);
                //     if (recordIdLocal !== null) {
                //         await declareStorePageDataModel(recordIdLocal);
                //         await declarePwaData(recordIdLocal);
                //     }
                // }
            }
        })();
        // console.log("load once");
    }, [ip, city, country, region, reload]);

    // console.log("pwaData", pwaData);
    // return { recordId, StorePageDataModel, pwaData, domainData };
    return {recordId, StorePageDataModel, pwaData, ipInfo};
};