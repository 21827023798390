import Cookies from "js-cookie";
import {useState} from "react";
import {SymbolsList} from "../enums/SymbolsList";
import {refineSearchParams} from "./refineSearchParams";


let bbgWasSet = false;
export const useBbgAcquire = () => {
    const bbgFromCookie = Cookies.get(SymbolsList.bbg) || SymbolsList.empty;
    const [bbg, setBbgOriginal] = useState(bbgFromCookie);

    const setBbg = () => {
        const {searchParams} = refineSearchParams();
        Cookies.set(SymbolsList.bbg, searchParams[SymbolsList.bbgCookieKey] || SymbolsList.empty, { expires: 7 });
        setBbgOriginal(searchParams[SymbolsList.bbgCookieKey] || SymbolsList.empty);

        // console.log("bbgFromCookie", Cookies.get(SymbolsList.bbg));
    };

    if (!bbgWasSet) {
        bbgWasSet = true;
        setBbg();
    }

    return {bbg, setBbg, setBbgOriginal};
};