
export type userAgentParsedType = {
    "ua": string,
}

export const userAgentParsed =  {
    "ua": "",
    "browser": {
        "name":  "",
        "version":  "",
        "major":  ""
    },
    "engine": {
        "name":  "",
        "version":  ""
    },
    "os": {
        "name": "",
        "version":  ""
    },
    "device": {
        "vendor":  "",
        "model":  "",
        "type":  ""
    },
    "cpu": {
        "architecture":  ""
    }
};
