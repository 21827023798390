// import ReactPixel, {AdvancedMatching} from "В";
// import ReactPixel from "react-facebook-pixel";
// import {FacebookPixelStandardEvents} from "../enums/FacebookPixelStandardEvents";
// import type { TrackerAction } from "../types/TrackerAction";


let hasNotInit = true;
export const initPixelIfNot = (pixel: string) => {
    if (hasNotInit) {
    //     const advancedMatching = { em: 'some@email.com' } as AdvancedMatching;
    //     const options = {
    //         autoConfig: true,
    //         debug: false,
    //     };
    //
    //     console.log(ReactPixel.init(pixel, advancedMatching, options), pixel, advancedMatching, options);
    //

        !function(f,b,e,v,n,t,s) // eslint-disable-line
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod? // eslint-disable-line
            n.callMethod.apply(n,arguments):n.queue.push(arguments)}; // eslint-disable-line
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; // eslint-disable-line
            n.queue=[];t=b.createElement(e);t.async=!0; // eslint-disable-line
            t.src=v;s=b.getElementsByTagName(e)[0]; // eslint-disable-line
            s.parentNode.insertBefore(t,s)}(window, document,'script', // eslint-disable-line
            'https://connect.facebook.net/en_US/fbevents.js'); // eslint-disable-line

        if (pixel) {
            if (Array.isArray(pixel)) {
                pixel.forEach(element => {
                    window.fbq('set', 'autoConfig', false, element);
                    window.fbq('init', element);
                });
            } else {
                window.fbq('set', 'autoConfig', false, pixel);
                window.fbq('init', pixel);
            }
        }

        window.fbq('track', 'PageView');
        
        hasNotInit = false;
    }
};


// export const doFacebookPixel = (eventTrack: TrackerAction, pixel: string) => {
//     initPixelIfNot(pixel);
//
//     if (eventTrack.action in FacebookPixelStandardEvents) {
//         const eventName: FacebookPixelStandardEvents = FacebookPixelStandardEvents[eventTrack.action as FacebookPixelStandardEvents];
//
//         ReactPixel.track(eventName);
//     }
// };
