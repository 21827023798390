import React from "react";
import '../App.css';
// @ts-ignore
import logo from "../logo.svg"
// import {useIpInfo} from "../hooks/useIpInfo";
// import {refineSearchParams} from "../hooks/refineSearchParams";
// import {useUaParser} from "../hooks/useUaParser";

export const WhitePageDeveloper = () => {
    // const { ipInfo } = useIpInfo();
    // const { searchParams } = refineSearchParams();
    // const { ua, locale } = useUaParser();

    // if (ipInfo === null) {
    //     return;
    // }

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                {/*<p>*/}
                {/*    Locale: { locale }*/}
                {/*</p>*/}
                {/*<p>*/}
                {/*    Search: { window.location.search === "" ? "?locale=ru-RU&country=TH&os=Android" : JSON.stringify(searchParams) }*/}
                {/*</p>*/}
                {/*<p>*/}
                {/*    WhitePage: { window.location.search === "" ? "?whitePage=https://callkeeper.ru" : JSON.stringify(searchParams) }*/}
                {/*</p>*/}
                {/*<p>*/}
                {/*    Trackers: { window.location.search === "" ? "?fbPixelId=&ttPixelId=&gaConversionId=" : JSON.stringify(searchParams) }*/}
                {/*    Trackers: { window.location.search === "" ? "?anValue=&anCurrency=" : JSON.stringify(searchParams) }*/}
                {/*</p>*/}
                {/*<p>*/}
                {/*    Actions: { window.location.search === "" ? "?fbPush=&fbClick=&fbInstall=" : JSON.stringify(searchParams) } <br/>*/}
                {/*    Actions: { window.location.search === "" ? "?ttPush=&ttClick=&ttInstall=" : JSON.stringify(searchParams) } <br/>*/}
                {/*    Actions: { window.location.search === "" ? "?gaPush=&gaClick=&gaInstall=" : JSON.stringify(searchParams) }*/}
                {/*</p>*/}
                {/*<p>*/}
                {/*    OS: { JSON.stringify(ua.os) }*/}
                {/*</p>*/}
                {/*<p>*/}
                {/*    Locale: { window.navigator.language }*/}
                {/*</p>*/}
                {/*<p>*/}
                {/*    Location: { JSON.stringify({ countryCode: ipInfo.countryCode, country: ipInfo.countryName }) }*/}
                {/*</p>*/}
            </header>
        </div>
    );
};
