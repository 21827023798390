import React from "react";
import snapBrowserStab from "!!raw-loader!./stab/snap-browser-stab.html"; // eslint-disable-line
import frenchStab from "!!raw-loader!./stab/fr-snap-stab.html"; // eslint-disable-line
import hindiStab from "!!raw-loader!./stab/hi-snap-stab.html"; // eslint-disable-line
import {WhitePageDeveloper} from "./WhitePageDeveloper";


interface StaticPageProps
{
    name: "snapBrowserStab",
}

export const StaticPage = ({name}: StaticPageProps) => {

    if (typeof name !== "undefined") {
        const language = window.navigator.language;

        if (language.includes("fr")) {
            return <div dangerouslySetInnerHTML={{__html: frenchStab }}></div>;
        } else if (language.includes("hi")) {
            return <div dangerouslySetInnerHTML={{__html: hindiStab }}></div>;
        } else {
            return <div dangerouslySetInnerHTML={{__html: snapBrowserStab }}></div>;
        }
    }

    return <WhitePageDeveloper/>;
};
